import { ContactModelKeys, IContact } from 'AppContext/Notifications/Contacts/Models/ContactModel';
import { IValidations } from 'views/Users/utils/UsersModels';

export const defaultContactValidation = [
    { name: ContactModelKeys.firstName, error: false, message: '' },
    { name: ContactModelKeys.lastName, error: false, message: '' },
    { name: ContactModelKeys.email, error: false, message: '' },
    { name: ContactModelKeys.phone, error: false, message: '' },
    { name: ContactModelKeys.url, error: false, message: '' }
] as IValidations[];

const isNullOrWhiteSpace = (text: string): boolean => {
    return !text?.trim();
};

const emailRegex = new RegExp(/^[\w-\\.]+@([\w-]+\.)+[\w-]{2,4}$/);
const isEmailInvalid = (email: string): boolean => {
    return !emailRegex.test(email);
};

const phoneRegex = new RegExp(/^\+?[0-9][0-9]{7,14}$/);
const isPhoneInvalid = (phone?: string): boolean => {
    return !phone || !phoneRegex.test(phone);
};

const urlRegex = new RegExp(
    /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\\+.~#?&\\/=]*)$/
);
const isUrlInvalid = (url?: string): boolean => {
    return !url || !urlRegex.test(url);
};

export const ValidateContactValue = (array: IValidations[], id: ContactModelKeys, value?: string | null): IValidations => {
    const validateValues = () => {
        let error = false;
        let error1 = false;
        switch (id) {
            case ContactModelKeys.email:
                error = isNullOrWhiteSpace(value ?? '');
                error1 = !!value && isEmailInvalid(value);
                return {
                    error: error || error1,
                    message: error ? `${id}_required` : error1 ? `${id}_invalid` : ''
                };
            case ContactModelKeys.phone:
                error = !!value && isPhoneInvalid(value);
                return { error: error, message: error ? `${id}_invalid` : '' };
            case ContactModelKeys.url:
                error = !!value && isUrlInvalid(value);
                return { error: error, message: error ? `${id}_invalid` : '' };

            default:
                error = isNullOrWhiteSpace(value ?? '');
                return { error: error, message: error ? `${id}_required` : '' };
        }
    };

    return (array[array.findIndex((x) => x.name == id)] = {
        ...array[array.findIndex((x) => x.name == id)],
        error: validateValues().error,
        message: validateValues().message
    });
};

export function ValidateContact(contact: IContact): IValidations[] {
    for (const [key, value] of Object.entries(contact).filter(([key]) => key !== 'id')) {
        ValidateContactValue(defaultContactValidation, key as ContactModelKeys, value ? (value as string) : null);
    }
    return defaultContactValidation;
}

export function isContactValid(contact: IContact): boolean {
    return ValidateContact(contact).filter((x) => x.error).length === 0;
}
