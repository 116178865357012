import { DeviceTypeConfiguration } from 'gql/graphql';

export interface IConfigurationProfile {
    id: number;
    name: string;
    aquisitionRate: number | null;
    aquisitionRateEarth: number | null;
    communicationRate: number | null;
    communicationRateEarth: number | null;
    serverUrl: string;
    isDefault: boolean;
    timeServerPort: number;
    timeServerProtocol: string;
    timeServerType: number | null;
    timeServerUrl: string;
    deviceTypeConfigurations: DeviceTypeConfiguration[];
    protectionEarthFrequencyF1: number;
    protectionEarthFrequencyF2: number;
    protectionEarthFrequencyF3: number;
    serviceEarthFrequencyF1: number;
    serviceEarthFrequencyF2: number;
    serviceEarthFrequencyF3: number;
    mqttUsername: string;
    mqttPassword: string;
    mqttCertificateUrl: string;
    mqttClientId: string;
    mqttGroupId: string;
    mqttServerUrl: string;
    dnsServerName1: string;
    dnsServerName2: string;
    proxyPort: number | null;
    proxyUrl: string | null;
    customDnsEnabled: boolean;
    proxyEnabled: boolean;
    emailServerType: number | null;
    emailServerUrl: string | null;
    serverEmailPort: number | null;
    emailUsername: string | null;
    encryptionType: number | null;
    emailSubjectExpression: string | null;
    emailBodyExpression: string | null;
    emailPassword: string | null;
    checkIntervalEmail: number | null;
    maxChecksEmail: number | null;
    emailEnabled: boolean;
    bluetoothTimeout: number | null;
    operationModes: ConfigurationModes | null;
}

export type ConfigurationModes = {
    npqSchedulers: Array<{ id: number; name: string }>;
    currentHarmonicsSchedulers: Array<{ id: number; name: string }>;
    energyBalanceSchedulers: Array<{ id: number; name: string }>;
};

export enum ConfigurationProfileModelKeys {
    id = 'id',
    name = 'name',
    bluetoothTimeout = 'bluetoothTimeout',
    aquisitionRate = 'aquisitionRate',
    aquisitionRateEarth = 'aquisitionRateEarth',
    communicationRate = 'communicationRate',
    communicationRateEarth = 'communicationRateEarth',
    serverUrl = 'serverUrl',
    isDefault = 'isDefault',
    timeServerPort = 'timeServerPort',
    timeServerProtocol = 'timeServerProtocol',
    timeServerType = 'timeServerType',
    timeServerUrl = 'timeServerUrl',
    deviceTypeConfigurations = 'deviceTypeConfigurations',
    protectionEarthFrequencyF1 = 'protectionEarthFrequencyF1',
    protectionEarthFrequencyF2 = 'protectionEarthFrequencyF2',
    protectionEarthFrequencyF3 = 'protectionEarthFrequencyF3',
    serviceEarthFrequencyF1 = 'serviceEarthFrequencyF1',
    serviceEarthFrequencyF2 = 'serviceEarthFrequencyF2',
    serviceEarthFrequencyF3 = 'serviceEarthFrequencyF3',
    mqttUsername = 'mqttUsername',
    mqttPassword = 'mqttPassword',
    mqttCertificateUrl = 'mqttCertificateUrl',
    mqttClientId = 'mqttClientId',
    mqttGroupId = 'mqttGroupId',
    mqttServerUrl = 'mqttServerUrl',
    dnsServerName1 = 'dnsServerName1',
    dnsServerName2 = 'dnsServerName2',
    proxyPort = 'proxyPort',
    proxyUrl = 'proxyUrl',
    customDnsEnabled = 'customDnsEnabled',
    proxyEnabled = 'proxyEnabled',
    emailServerType = 'emailServerType',
    emailServerUrl = 'emailServerUrl',
    serverEmailPort = 'serverEmailPort',
    emailUsername = 'emailUsername',
    encryptionType = 'encryptionType',
    emailSubjectExpression = 'emailSubjectExpression',
    emailBodyExpression = 'emailBodyExpression',
    emailPassword = 'emailPassword',
    checkIntervalEmail = 'checkIntervalEmail',
    maxChecksEmail = 'maxChecksEmail',
    emailEnabled = 'emailEnabled',
    operationModes = 'operationModes'
}

export const enum TimerServerType {
    NTP = 0, //without APN configuration
    HTP = 1,
    DEEPGRID = 2
}

export const enum EmailServerType {
    IMAP = 2,
    POP = 1
}

export const enum EncryptionType {
    SSL = 2,
    TLS = 1
}
