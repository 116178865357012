import RemoteConfiguration from '../../models/settings/RemoteConfiguration';
import { RemoteUserSettings } from '../../models/settings/RemoteUserSettings';
import { AccessGroupsGridsResponse, Configuration } from './Configuration';
import { emptyRemoteConfiguration } from './Mocks';

interface IGetUserAssets {
    configuration: Configuration;
    accessGroupIds?: number[];
    companiesIds?: number[];
    regionsIds?: number[];
    operationalAreasIds?: number[];
    assetsIds?: number[];
}

class SettingsFetcher {
    static async getUserSettings(token: string, configuration: Configuration) {
        try {
            const response = await fetch(`${configuration.BaseUrl}/authorization/api/User/getusersetting`, {
                method: 'POST',
                body: JSON.stringify({ idToken: token }),
                headers: { 'Content-Type': 'application/json' }
            });

            const object = (await response.json()) as RemoteUserSettings;

            if (object.userSettings !== null) {
                try {
                    // Xanato here! This line needs to be changed once object is created on BE!
                    object.userSettings = JSON.parse(object.userSettings.toString()) as RemoteConfiguration;
                } catch (e) {
                    object.userSettings = emptyRemoteConfiguration();
                }
            } else if (object.userSettings === null) {
                object.userSettings = emptyRemoteConfiguration();
            }
            return object;
        } catch (e) {
            console.error(e);
        }
    }

    static async postUserSettings(userSetting: RemoteUserSettings, configuration: Configuration) {
        try {
            await fetch(`${configuration.BaseUrl}/authorization/api/User/usersetting`, {
                method: 'POST',
                body: JSON.stringify({ ...userSetting, userSettings: JSON.stringify(userSetting.userSettings) }),
                headers: { 'Content-Type': 'application/json' }
            });
        } catch (e) {
            console.error(e);
        }
    }

    static async GetUserAccessGroupGrids({
        configuration,
        accessGroupIds
    }: IGetUserAssets): Promise<AccessGroupsGridsResponse | undefined> {
        try {
            const response = await fetch(`${configuration.BaseUrl}/layout/api/Asset/${(accessGroupIds && accessGroupIds[0]) ?? 0}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            if (response.ok) {
                const object = (await response.json()) as AccessGroupsGridsResponse;

                return object;
            }
        } catch (e) {
            console.error;
        }
    }
}

export default SettingsFetcher;
