import { MapMarker } from '_global/Components/base/map/logic/MapTypes';
import { SelectChangeEvent } from '@mui/material';
import { AlarmStandardList } from 'AppContext/Alarms/AlarmProfiles/Models/AlarmStandard';
import { IOpAreas } from 'AppContext/Enterprise/OperationalAreas/Models/OpAreasModel';
import { EneidaAutocompleteValue } from 'Core/Components/Atoms/Autocomplete';
import { Device } from 'models/asset/AssetDevice';
import { CATEGORY } from 'models/asset/AssetHistoricalData';
import { ASSET_TYPE_PROTECTED } from 'models/asset/AssetInfo';
import { AssetModel, Feeder, GenericFeederType, Transformer } from 'models/asset/AssetModel';
import { CableTypeResponse } from 'models/cables/CableTypeResponse';
import { ProtectionEarth, ServiceEarth, Transformers } from 'models/dataStorage/NewSimpleLastData';
import { ProtectionEarthResponse } from 'models/dataStorage/ProtectionEarth';
import Mode from 'models/enums/ModeEnum';
import { TransformerType } from 'models/enums/TransformerType';
import Type from 'models/enums/TypeEnum';
import { HVSubstations_assets_nodes } from 'queries/Types/HVSubstations';
import { RegionList_regions_nodes } from 'queries/Types/RegionList';
import { ChangeEvent } from 'react';
import { IValidations } from 'views/Users/utils/UsersModels';

export type IFeederTableHead = {
    mode: Mode;
    feederType: GenericFeederType;
};

export interface ICategory {
    onChange: (value: CATEGORY) => void;
    category: CATEGORY;
    hasEarthData: boolean;
    hasLastData: boolean;
}

export interface IHistoricalDataFilters {
    asset: AssetModel;
    category: CATEGORY;
    clearFilter: boolean;
    onFiltersChange: () => void;
    onConfirmPressed: boolean;
    onSaveGrafanaFilter: (filters: string) => void;
}

export interface BasicDataModel {
    id: number;
    name: string;
}

export interface MapDataModel {
    initialPoint: MapMarker;
    centerPoint: MapMarker;
    operationalArea: IOpAreas | null;
}

export interface AssetDetailsProps {
    assetId?: number;
    clone?: boolean;
}

export interface LastDataTitleProps {
    lastCommonData?: Transformers;
    lastEarthData?: Array<ProtectionEarth | ServiceEarth>;
    protectionEarth?: ProtectionEarthResponse;
    isLoading?: boolean;
    title: string;
    onRefresh: () => void;
    onExportCommonData?: (transformerItem: Transformers) => void;
    onExportEarthData?: (earthItems: Array<ProtectionEarth | ServiceEarth>) => void;
}

export interface ResponseType {
    id: number;
    name: string;
    aquisitionRate: number;
    communicationRate: number;
}

export interface DevicesTableProps {
    assetDetails: AssetModel;
    mode: Mode;
    fetchAsset: () => void;
}
export interface DevicesTableRowProps {
    device: Device;
    fetchAsset: () => void;
}

export type DevicesTableBodyProps = {
    data: Device[];
    fetchAsset: () => void;
};

export const enum LastDataOptions {
    GLOBAL = 'global',
    EARTH = 'earth'
}
export interface ILastDataStatusRequest {
    global: boolean;
    earth: boolean;
}

export interface IInfoDisplayAddProps {
    assetDetails: AssetModel;
    assetInfoData: IAssetInfoDataModel;
    isCloning?: boolean;
    handleChangeNumber?: ((newValue: number, id: string) => void) | undefined;
    handleOnChange?: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, id: string) => void;
    handleChange?: (newValue: string, id: string) => void;
    handleChangeAssetType?: (newValue: ASSET_TYPE_PROTECTED) => void;
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    handleChangeBool?: (newValue: boolean, id: string) => void;
    handleChangeAlarm: (newValue: EneidaAutocompleteValue | EneidaAutocompleteValue[] | null) => void;
    handleChangeBulk: (newValue: EneidaAutocompleteValue | EneidaAutocompleteValue[] | null) => void;
    handleChangeRegion: (newValue: EneidaAutocompleteValue | EneidaAutocompleteValue[] | null) => void;
    handleChangeOpArea: (newValue: EneidaAutocompleteValue | EneidaAutocompleteValue[] | null) => void;
    updateTransformerType?: (number: TransformerType) => void;
}

export interface IAssetInfoDataModel {
    assetType: BasicDataModel[];
    assetParent: BasicDataModel[];
    assetFeeder: BasicDataModel[];
    optionsHvSubstation: HVSubstations_assets_nodes[] | null | undefined;
    optionsHvFeeder: BasicDataModel[];
    opAreaSelected: MapDataModel;
}

export interface IAssetProps {
    assetDetails: AssetModel;
    isCloning?: boolean;
    handleOnChange?: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, id: string) => void;
    handleOnChangeTransfomerInfo?: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, id: string) => void;
    handleChange?: (newValue: string, id: string) => void;
    handleChangeTransfomerInfo?: (newValue: string, id: string) => void;
    handleChangeNumber?: (newValue: number, id: string) => void;
    handleChangeBulk: (newValue: EneidaAutocompleteValue | EneidaAutocompleteValue[] | null) => void;
    updateFeeders?: (feeders: Feeder[]) => void;
    updateTransformerType?: (number: TransformerType) => void;
    handleChangeAlarm: (newValue: EneidaAutocompleteValue | EneidaAutocompleteValue[] | null) => void;
    handleChangeAssetType?: (newValue: ASSET_TYPE_PROTECTED) => void;
    handleChangeBool?: (newValue: boolean, id: string) => void;
    handleChangeRegion: (newValue: EneidaAutocompleteValue | EneidaAutocompleteValue[] | null) => void;
    handleChangeOpArea: (newValue: EneidaAutocompleteValue | EneidaAutocompleteValue[] | null) => void;
}

export interface AssetTransformerProps {
    assetDetails: AssetModel;
    updateTransformers: (transformers: Transformer[]) => void;
}
export interface IFeederTable {
    feeders: Feeder[];
    updateFeeders: (feeders: Feeder[]) => void;
    feederType: GenericFeederType;
}

export type IFeederTableRow = {
    feeder: Feeder;
    cableTypeOptions: CableTypeResponse[];
    setFeederToDelete: (value: number) => void;
    handleOnChange: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, id: string, index: number, type: Type) => void;
    handleChange: (newValue: number | null, id: string, index: number) => void;
    handleChangeSelect: (event: SelectChangeEvent, id: string, index: number, type: Type) => void;
    handleEraseCableTypeAndFuseRating: (index: number) => void;
    standardsOption: AlarmStandardList[];
    setOpenModal: (value: boolean) => void;
    mode: Mode;
};

export type IFeederTableBody = {
    mode: Mode;
    feeders: Feeder[];
    cableTypeOptions: CableTypeResponse[];
    setFeederToDelete: (value: number) => void;
    handleChange: (newValue: number | null, id: string, index: number) => void;
    handleOnChange: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, id: string, index: number, type: Type) => void;
    handleChangeSelect: (event: SelectChangeEvent, id: string, index: number, type: Type) => void;
    standardsOption: AlarmStandardList[];
    setOpenModal: (value: boolean) => void;
    handleEraseCableTypeAndFuseRating: (index: number) => void;
};

export interface AssetTypeSettingsProps {
    assetDetails: AssetModel;
    assetInfoData: IAssetInfoDataModel;
    mode: Mode;
    handleChangeNumber?: ((newValue: number, id: string) => void) | undefined;
    handleChange?: (newValue: string, id: string) => void;
    handleChangeAssetType?: (newValue: ASSET_TYPE_PROTECTED) => void;
}

export interface AssetInfoImportDataModel {
    assetType: BasicDataModel[];
    assetParent: BasicDataModel[];
    optionsRegions: (RegionList_regions_nodes | null)[] | null | undefined;
}

export interface IImportValidationAssets {
    valid: boolean;
    message: string;
}

export const defaultValidationFormAssets = [
    {
        name: 'name',
        hasError: false
    },
    {
        name: 'assetType',
        hasError: false
    },
    {
        name: 'registryNumber',
        hasError: false
    },
    {
        name: 'latitude',
        hasError: false
    },
    {
        name: 'longitude',
        hasError: false
    },
    {
        name: 'regionId',
        hasError: false
    },
    {
        name: 'operationalAreaId',
        hasError: false
    },
    {
        name: 'power',
        hasError: false
    },
    {
        name: 'name',
        hasError: false
    },
    {
        name: 'transformerUniqueIdentifier',
        hasError: false
    },
    {
        name: 'sdsType',
        hasError: false
    },
    {
        name: 'idV2',
        hasError: false
    },
    {
        name: 'feederV2Id',
        hasError: false
    },
    {
        name: 'feederName',
        hasError: false
    },
    {
        name: 'transformerName',
        hasError: false
    },
    {
        name: 'operationalAreaV2Id',
        hasError: false
    },
    {
        name: 'regionV2Id',
        hasError: false
    },
    {
        name: 'parentAssetV2Id',
        hasError: false
    },
    {
        name: 'parentFeederV2Id',
        hasError: false
    }
];

export const defaultCheckImportAssets = [
    { name: 'name', error: false, message: '' },
    { name: 'registryNumber', error: false, message: '' },
    { name: 'phaseRotation', error: false, message: '' },
    { name: 'phaseType', error: false, message: '' },
    { name: 'power', error: false, message: '' },
    { name: 'latitude', error: false, message: '' },
    { name: 'longitude', error: false, message: '' },
    { name: 'numberOfFeeders', error: false, message: '' },
    { name: 'feederFuseRating', error: false, message: '' },
    { name: 'operationalAreaCode', error: false, message: '' },
    { name: 'brand', error: false, message: '' },
    { name: 'model', error: false, message: '' },
    { name: 'transformerUniqueIdentifier', error: false, message: '' },
    { name: 'sdsType', error: false, message: '' },
    { name: 'idV2', error: false, message: '' },
    { name: 'feederV2Id', error: false, message: '' },
    { name: 'feederName', error: false, message: '' },
    { name: 'transformerName', error: false, message: '' },
    { name: 'operationalAreaV2Id', error: false, message: '' },
    { name: 'regionV2Id', error: false, message: '' },
    { name: 'parentAssetV2Id', error: false, message: '' },
    { name: 'parentFeederV2Id', error: false, message: '' },
    { name: 'isMainTansformer', error: false, message: '' },
    { name: 'transformerType', error: false, message: '' },
    { name: 'parentAssetId', error: false, message: '' },
    { name: 'parentFeederId', error: false, message: '' },
    { name: 'allMessages', error: false, message: '' }
] as IValidations[];

export const defaultImportAssetValidation: IImportValidationAssets = { valid: true, message: '' };
