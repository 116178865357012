import { qrCodeStyling } from '_global/Components/base/qr-code/QRCodeStyling';
import QrCodeView from '_global/Components/base/qr-code/QRCodeView';
import { COLORS } from '_global/Utils/Colors';
import { useConfigurationAtom } from '_global/Utils/hooks/jotai';
import { Box, Button, Grid, Typography, useMediaQuery } from '@mui/material';
import { useAtomValue } from 'jotai';
import { useRemoteConfiguration } from 'logic/configuration/Hooks';
import { QrCode, QrCodeResp } from 'models/qr-code/QRCode';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

const Overview = () => {
    const [config] = useRemoteConfiguration();
    const configuration = useAtomValue(useConfigurationAtom);
    const matchesPhone = useMediaQuery('(max-width:600px)');
    const [changeQr, setChangeQr] = useState(false);

    async function getDataQrcode() {
        if (!configuration) return;
        try {
            const response = await fetch(`${configuration.BaseUrl}/authorization/api/QRCode/get-by-name`, {
                method: 'POST',
                body: JSON.stringify({ name: 'Generic' }),
                headers: { 'Content-Type': 'application/json' }
            });

            return response;
        } catch (e) {
            console.error(e);
        }
    }

    const fetchData = async () => {
        const data = await getDataQrcode();

        if (data) {
            const response = (await data.json()) as QrCodeResp;
            delete response.id;

            const correctObj = { ...response } as unknown as QrCode;

            correctObj.initialMapLocation = {
                Longitude: response.initialMapLocation.longitude,
                Latitude: response.initialMapLocation.latitude
            };

            qrCodeStyling.update({
                data: JSON.stringify(correctObj),
                width: matchesPhone ? 300 : 600,
                height: matchesPhone ? 300 : 600
            });
        }
    };

    useEffect(() => {
        config && void fetchData();
    }, [config]);

    return (
        <Box sx={{ padding: 2, overflowY: 'scroll', height: '100%', scrollBehavior: 'auto', flexWrap: 'nowrap' }}>
            <Grid alignItems="center" container direction="column" justifyContent="center">
                <Typography sx={{ mt: 2, mb: 3 }} variant={matchesPhone ? 'h4' : 'h2'}>
                    eneida EdgeForce
                </Typography>

                <QrCodeView changeQr={changeQr} qr={qrCodeStyling} />

                <Button
                    onClick={(event) => {
                        event.preventDefault();
                        setChangeQr(!changeQr);
                    }}
                    size="medium"
                    sx={{ color: 'white', background: COLORS.ENEIDA_ALTERNATIVE_BLUE, mt: 2 }}
                    variant={'contained'}
                >
                    <FormattedMessage id={'alt_qrcode'} />
                </Button>
            </Grid>
        </Box>
    );
};

export default Overview;
