import { userLanguageAtom } from '_global/Utils/hooks/jotai';
import { useAtomValue } from 'jotai';
import { DefaultRootStateProps } from 'models';
import React, { useEffect, useState } from 'react';
import { IntlProvider, MessageFormatElement } from 'react-intl';
import { useSelector } from 'react-redux';

const loadLocaleData = (locale: string) => {
    switch (locale) {
        case 'nl':
            return import('_global/Resources/locales/nl/compiled.json');
        case 'pt-PT':
            return import('_global/Resources/locales/pt-PT/compiled.json');
        case 'de-DE':
            return import('_global/Resources/locales/de/compiled.json');
        case 'en-NZ':
            return import('_global/Resources/locales/en-NZ/compiled.json');
        case 'en-AU':
            return import('_global/Resources/locales/en-AU/compiled.json');
        default:
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            return import('_global/Resources/locales/en-GB/compiled.json');
    }
};

export interface LocalsProps {
    children: React.ReactNode;
}

const Locales = ({ children }: LocalsProps) => {
    const customization = useSelector((state: DefaultRootStateProps) => state.customization);
    const [messages, setMessages] = useState<Record<string, string> | Record<string, MessageFormatElement[]> | undefined>();
    const [local, setLocale] = useState('en-GB');
    const language = useAtomValue(userLanguageAtom);

    useEffect(() => {
        if (language) {
            setLocale(language);
            loadLocaleData(language)
                .then((d: { default: Record<string, string> | Record<string, MessageFormatElement[]> | undefined }) => {
                    setMessages(d.default);
                })
                .catch(console.error);
        } else {
            loadLocaleData(customization.locale)
                .then((d: { default: Record<string, string> | Record<string, MessageFormatElement[]> | undefined }) => {
                    setMessages(d.default);
                })
                .catch(console.error);
        }
    }, [language, customization.locale]);

    return (
        <>
            {messages && (
                <IntlProvider defaultLocale="en-GB" locale={local} messages={messages} onError={(e) => e}>
                    {children}
                </IntlProvider>
            )}
        </>
    );
};

export default Locales;
