import { removeAllGridsPagination } from '_global/Services/store/gridsPaginationSlice';
import { useAppDispatch } from '_global/Services/store/hooks';
import { useConfigurationAtom } from '_global/Utils/hooks/jotai';
import { faArrowRightFromBracket } from '@fortawesome/pro-regular-svg-icons';
import { Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useKeycloak } from '@react-keycloak/web';
import { useAtomValue } from 'jotai';
import { FormattedMessage } from 'react-intl';

import { IconToNode } from '../../../../../_global/Utils/IconToNode';
import { removeAllTabs } from '../../../../../_global/Utils/Tabs';

const LogoutSection = () => {
    const theme = useTheme();
    const dispatch = useAppDispatch();
    const { keycloak } = useKeycloak();

    const configuration = useAtomValue(useConfigurationAtom);

    const handleLogout = async () => {
        try {
            dispatch(removeAllGridsPagination());
            removeAllTabs();

            await keycloak.logout({
                redirectUri:
                    !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
                        ? `${window.location.origin}/login`
                        : `${configuration?.BaseUrl ?? ''}/login`
            });
        } catch (err) {
            console.error(err);
        }
    };

    return (
        <Button
            endIcon={<IconToNode definition={faArrowRightFromBracket} />}
            onClick={() => void handleLogout()}
            sx={{
                border: '1px solid',
                borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light,
                background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light,
                color: theme.palette.mode === 'dark' ? theme.palette.warning.dark : theme.palette.secondary.dark,
                transition: 'all .2s ease-in-out',
                '&[aria-controls="menu-list-grow"],&:hover': {
                    borderColor: theme.palette.secondary.main,
                    background: theme.palette.secondary.main,
                    color: theme.palette.primary.light
                }
            }}
        >
            <FormattedMessage id="logout" />
        </Button>
    );
};

export default LogoutSection;
