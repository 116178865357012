import QRCodeStyling from 'qr-code-styling';

export const qrCodeStyling = new QRCodeStyling({
    width: 600,
    height: 600,
    image: 'https://codaio.imgix.net/docs/iFaEgRVAVL/blobs/bl-Mxj0qJudVi/26b0e9faf5dfb82b1e1037b616adf6fa9fe0d2a13ecd72dd151393bef054e682836633f59d23439a9417c1208ecb24ab5c7f3f342f61ae70334c34ebd710958169ad277c448129b98cb08c7e542a39219f281a9b6ff12135e4505327acc69d34e9b95e92?auto=format%2Ccompress&fit=max',
    dotsOptions: {
        color: '#2C4153',
        type: 'rounded'
    },
    imageOptions: {
        crossOrigin: 'anonymous',
        margin: 20
    },
    qrOptions: {
        errorCorrectionLevel: 'H'
    }
});
