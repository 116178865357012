/// <reference types="vite-plugin-svgr/client" />
import EneidaLogo from '_global/Resources/assets/images/eneidaLogo.svg?react';
import { ButtonBase } from '@mui/material';
// project imports
import config from 'config';
import { useRemoteUserAccess } from 'logic/configuration/Hooks';
import { Link } from 'react-router-dom';

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => {
    const hasWebAccess = useRemoteUserAccess();
    return (
        <ButtonBase component={Link} disableRipple to={hasWebAccess ? config.defaultPath : config.defaultCommissionerPath}>
            <EneidaLogo />
        </ButtonBase>
    );
};

export default LogoSection;
