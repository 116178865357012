import { IconToNodeXanato } from '_global/Utils/IconToNode';
import { faCircleNodes, faMicrochip as faMicrochipAlt, faPlug, faSliders } from '@fortawesome/pro-duotone-svg-icons';
import { faObjectsColumn, faUsers } from '@fortawesome/pro-regular-svg-icons';
import {
    faAddressCard,
    faBellOn,
    faBolt,
    faBook,
    faBuilding,
    faBuildingFlag,
    faCalendarClock,
    faChartNetwork,
    faCloudCheck,
    faConveyorBeltArm,
    faEnvelope,
    faFileChartPie,
    faFileCode,
    faFiles,
    faGear,
    faGears,
    faLaptopCode,
    faMapLocationDot,
    faMicrochip,
    faMonitorWaveform,
    faMountains,
    faPenRuler,
    faPlugCircleCheck,
    faSdCards,
    faSimCards,
    faSiren,
    faSirenOn,
    faStopwatch,
    faTruckRampBox,
    faUser,
    faUserGear,
    faUserLock,
    faUsersRectangle
} from '@fortawesome/pro-solid-svg-icons';
import { useRemoteConfiguration } from 'logic/configuration/Hooks';
import { NavItemType } from 'models';
import { Children } from 'models/settings/RemoteUserSettings';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

interface Sidebar {
    id: string;
    type: string;
    title: JSX.Element;
    url?: string | null;
    external?: boolean;
    target?: boolean;
    icon?: () => JSX.Element;
    children: Sidebar[];
}

const getIcon: Record<string, () => JSX.Element> = {
    faCircleNodes: IconToNodeXanato(faCircleNodes),
    faPlug: IconToNodeXanato(faPlug),
    faSliders: IconToNodeXanato(faSliders),
    faObjectsColumn: IconToNodeXanato(faObjectsColumn),
    faCloudCheck: IconToNodeXanato(faCloudCheck),
    faFileChartPie: IconToNodeXanato(faFileChartPie),
    faFiles: IconToNodeXanato(faFiles),
    faGear: IconToNodeXanato(faGear),
    faGears: IconToNodeXanato(faGears),
    faBolt: IconToNodeXanato(faBolt),
    faSiren: IconToNodeXanato(faSiren),
    faSirenOn: IconToNodeXanato(faSirenOn),
    faMicrochipAlt: IconToNodeXanato(faMicrochipAlt),
    faUsers: IconToNodeXanato(faUsers),
    faBuilding: IconToNodeXanato(faBuilding),
    faBuildingFlag: IconToNodeXanato(faBuildingFlag),
    faMapLocationDot: IconToNodeXanato(faMapLocationDot),
    faMicrochip: IconToNodeXanato(faMicrochip),
    faMountains: IconToNodeXanato(faMountains),
    faUser: IconToNodeXanato(faUser),
    faUserLock: IconToNodeXanato(faUserLock),
    faUsersRectangle: IconToNodeXanato(faUsersRectangle),
    faSdCards: IconToNodeXanato(faSdCards),
    faBook: IconToNodeXanato(faBook),
    faCalendarClock: IconToNodeXanato(faCalendarClock),
    faUserGear: IconToNodeXanato(faUserGear),
    faConveyorBeltArm: IconToNodeXanato(faConveyorBeltArm),
    faSimCards: IconToNodeXanato(faSimCards),
    faPenRuler: IconToNodeXanato(faPenRuler),
    faTruckRampBox: IconToNodeXanato(faTruckRampBox),
    faLaptopCode: IconToNodeXanato(faLaptopCode),
    faChartNetwork: IconToNodeXanato(faChartNetwork),
    faPlugCircleCheck: IconToNodeXanato(faPlugCircleCheck),
    faMonitorWaveform: IconToNodeXanato(faMonitorWaveform),
    faStopwatch: IconToNodeXanato(faStopwatch),
    faEnvelope: IconToNodeXanato(faEnvelope),
    faAddressCard: IconToNodeXanato(faAddressCard),
    faBellOn: IconToNodeXanato(faBellOn),
    faFileCode: IconToNodeXanato(faFileCode)
};

function renderMenu() {
    const [config] = useRemoteConfiguration();
    const [menu, setMenu] = useState<{ items: NavItemType[] } | null>(null);

    useEffect(() => {
        if (config) {
            const menuSidebar = config.sidebar?.sections?.map((section) => {
                const menuStructure: Sidebar = {
                    id: section.section,
                    title: <FormattedMessage id={section.i18n ?? 'NA'} />,
                    type: section.type,
                    children: []
                };

                function recursiveMenuBuild(data: Children[]) {
                    return data.map((el: Children) => {
                        const childMenu: Sidebar = {
                            id: el.name,
                            title: <FormattedMessage id={el.i18n ?? 'NA'} />,
                            icon: getIcon[el.icon],
                            type: el.type,
                            url: el?.url,
                            external: el.externalUrl,
                            children: [],
                            target: el.externalUrl
                        };
                        if (el.children.length > 0) childMenu.children = recursiveMenuBuild(el.children);
                        return childMenu;
                    });
                }
                menuStructure.children = recursiveMenuBuild(section.children);
                return menuStructure;
            });
            setMenu({ items: menuSidebar as NavItemType[] });
        }
    }, [config]);

    return menu;
}

export default renderMenu;
