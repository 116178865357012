import { requestUrlIncludes, stringIncludes } from '_global/Utils/ArrayUtils';
import { useConfigurationAtom } from '_global/Utils/hooks/jotai';
import { useKeycloak } from '@react-keycloak/web';
import { wrapUseRoutes } from '@sentry/react';
import config from 'config';
import { showLoadingAtom, showSpecialLoadingAtom } from 'controllers/layout/MainLayout';
import fetchIntercept from 'fetch-intercept';
import { useAtomValue, useSetAtom } from 'jotai';
import { useRemoteUserAccess } from 'logic/configuration/Hooks';
import { ReactElement, useEffect, useMemo } from 'react';
import { useLocation, useNavigate, useRoutes } from 'react-router-dom';

import { InterceptorRequest } from '../../models/authorization/InterceptorRequest';
import LoginRoutes from './LoginRoutes';
import mainRoutes from './MainRoutes';

const useSentryRoutes = wrapUseRoutes(useRoutes);

export default function AppRoutes(): ReactElement | null {
    const configuration = useAtomValue(useConfigurationAtom);
    const { keycloak } = useKeycloak();
    const navigate = useNavigate();
    const location = useLocation();
    const isLoggedIn = useMemo(() => keycloak.authenticated, [keycloak, keycloak.authenticated]);
    const hasWebAccess = useRemoteUserAccess();
    const setShowSpecialLoading = useSetAtom(showSpecialLoadingAtom);
    const setShowLoading = useSetAtom(showLoadingAtom);

    useEffect(() => {
        if (isLoggedIn && (location.pathname === '/login' || location.pathname === '/') && hasWebAccess !== undefined) {
            navigate(hasWebAccess ? config.defaultPath : config.defaultCommissionerPath);
        }
    }, [isLoggedIn, hasWebAccess]);

    useEffect(() => {
        fetchIntercept.register({
            request: function (url: Request | string, config: InterceptorRequest) {
                let address: string;

                if (url instanceof Request) address = url.url;
                else address = url;
                if (configuration && configuration.EnabledAuthentication && !stringIncludes(address, configuration.ExternalRequests)) {
                    if (requestUrlIncludes(address)) {
                        return Promise.reject();
                    }

                    if (config.body instanceof FormData && !window.location.pathname.includes('/documents')) {
                        return [url, config];
                    }

                    if (keycloak.idToken !== undefined && config) {
                        if ((config?.method === 'POST' || config?.method === 'PUT') && !address.toString().includes('getusersetting')) {
                            setShowSpecialLoading(true);
                        } else {
                            setShowLoading(true);
                        }

                        if (window.location.pathname.includes('/documents')) {
                            delete config.headers['Content-Type'];
                        }

                        config.headers.Authorization = `Bearer ${keycloak.idToken}`;
                        return [address, config];
                    } else {
                        setShowSpecialLoading(false);
                        setShowLoading(false);
                        return Promise.reject();
                    }
                } else {
                    return [address, config];
                }
            },

            requestError: function (error) {
                setShowSpecialLoading(false);
                setShowLoading(false);
                return Promise.reject(error);
            },

            response: function (response) {
                setShowSpecialLoading(false);
                setShowLoading(false);
                return response;
            },

            responseError: function (error) {
                setShowSpecialLoading(false);
                setShowLoading(false);
                return Promise.reject(error);
            }
        });
    }, [configuration]);

    if (configuration && configuration.EnabledAuthentication) {
        return useSentryRoutes([mainRoutes() ?? LoginRoutes]);
    } else {
        return useSentryRoutes([LoginRoutes]);
    }
}
