export const COLORS = {
    // RED
    ENEIDA_RED: '#d32f2f',
    ENEIDA_ALTERNATIVE_RED: '#d84315',
    ENEIDA_SIMPLE_LIGHT_RED: '#fbdfd6',
    // GREEN
    ENEIDA_GREEN: '#2e7d32',
    ENEIDA_ALTERNATIVE_GREEN: '#00c853',
    ENEIDA_SIMPLE_LIGHT_GREEN: '#ddffe6',
    // BLUE
    ENEIDA_BLUE: '#405a73',
    ENEIDA_ALTERNATIVE_BLUE: '#2d3f51',
    ENEIDA_SIMPLE_BLUE: '#0288d1',
    ENDEIDA_SIMPLE_LIGHT_BLUE: '#e3f2ff',
    ENEIDA_SOLID_BLUE: '#2196f3',
    // YELLOW
    ENEIDA_YELLOW: '#eeba17',
    ENEIDA_ALTERNATIVE_YELLOW: '#ffc107',
    ENEIDA_LIGHT_YELLOW: '#f5df9f',
    ENEIDA_STRONG_YELLOW: '#f7bb05',
    ENEIDA_SIMPLE_YELLOW: '#FFF8D2',
    // GREY
    ENEIDA_GREY: '#c7c7c7',
    ENEIDA_SIMPLE_GREY: '#F0F0F0',
    ENEIDA_ALTERNATIVE_GREY: '#7D7D7D'
};
