import { IconToNode } from '_global/Utils/IconToNode';
import { faBars } from '@fortawesome/pro-light-svg-icons';
import { Avatar, Box, ButtonBase } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useRemoteUserAccess } from 'logic/configuration/Hooks';

import LogoSection from '../LogoSection';
import AccessGroupSection from './AccessGroupSection';
import LanguageGroupSection from './LanguageGroupSection';
import LogoutSection from './LogoutSection';
import MobileSection from './MobileSection';
import ProfileSection from './ProfileSection';

export interface HeaderProps {
    handleLeftDrawerToggle: () => void;
}
const Header = ({ handleLeftDrawerToggle }: HeaderProps) => {
    const theme = useTheme();
    const hasWebAccess = useRemoteUserAccess();

    return (
        <>
            {/* logo & toggler button */}
            <Box
                sx={{
                    width: 228,
                    display: 'flex',
                    [theme.breakpoints.down('md')]: {
                        width: 'auto'
                    }
                }}
            >
                <Box component="span" sx={{ display: { xs: 'none', md: 'block' }, flexGrow: 1 }}>
                    <LogoSection />
                </Box>
                {hasWebAccess && (
                    <ButtonBase sx={{ borderRadius: '12px', overflow: 'hidden' }}>
                        <Avatar
                            color="inherit"
                            onClick={handleLeftDrawerToggle}
                            sx={{
                                transition: 'all .2s ease-in-out',
                                background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.secondary.light,
                                color: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.secondary.dark,
                                '&:hover': {
                                    background: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.secondary.dark,
                                    color: theme.palette.mode === 'dark' ? theme.palette.secondary.light : theme.palette.secondary.light
                                }
                            }}
                            variant="rounded"
                        >
                            <IconToNode definition={faBars} size="sm" />
                        </Avatar>
                    </ButtonBase>
                )}
            </Box>

            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ flexGrow: 1 }} />

            {hasWebAccess ? (
                <>
                    <Box sx={{ display: { xs: 'none', sm: 'block' }, mr: 3 }}>
                        <AccessGroupSection />
                    </Box>
                    <Box sx={{ display: { xs: 'none', sm: 'block' }, mr: 3 }}>
                        <LanguageGroupSection />
                    </Box>

                    <ProfileSection />
                </>
            ) : (
                <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                    <LogoutSection />
                </Box>
            )}

            {/* mobile header */}
            <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                <MobileSection />
            </Box>
        </>
    );
};

export default Header;
