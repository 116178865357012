import {
    lastUserSettingsAtom,
    stateModalAtom,
    stateToastAtom,
    userAccessGroupAtom,
    userAccessGroupResponseAtom
} from '_global/Utils/hooks/jotai';
import { Autocomplete, TextField } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ItemAccessGroup } from 'AppContext/UsersAndGroups/AccessGroups/Models/AccessGroupModel';
import config from 'config';
import { useAtom, useSetAtom } from 'jotai';
import LocalStorageManager from 'logic/local/LocalStorageManager';
import MessageType from 'models/enums/MessageTypes';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

const AccessGroupSection = () => {
    const intl = useIntl();
    const navigate = useNavigate();
    const theme = useTheme();
    const [userConfig, setUserConfig] = useAtom(lastUserSettingsAtom);
    const [userAccessGroup, setUserAccessGroup] = useAtom(userAccessGroupAtom);
    const setUserAccessGroupResponse = useSetAtom(userAccessGroupResponseAtom);
    const setStateModal = useSetAtom(stateModalAtom);
    const setStateToast = useSetAtom(stateToastAtom);

    const setUserSettings = (ag: ItemAccessGroup) => {
        if (userConfig) {
            setUserConfig({
                ...userConfig,
                userSettings: {
                    ...userConfig?.userSettings,
                    selectedAccessGroup: ag ? ag : null
                }
            });
        }
    };

    useEffect(() => {
        if (
            userConfig?.userSettings?.selectedAccessGroup &&
            userConfig.accessGroups.find((ag) => ag.id === userConfig?.userSettings?.selectedAccessGroup?.id) !== undefined
        ) {
            setUserAccessGroup(userConfig.userSettings.selectedAccessGroup);
            setUserAccessGroupResponse({
                assets: [],
                workingOPArea: [],
                workingRegions: [],
                devices: []
            });
        } else if (
            userConfig?.userSettings?.selectedAccessGroup &&
            userConfig.accessGroups.find((ag) => ag.id === userConfig?.userSettings?.selectedAccessGroup?.id) === undefined
        ) {
            setUserAccessGroup(userConfig.accessGroups.sort((a, b) => a.id - b.id)[0]);
            setUserSettings(userConfig.accessGroups.sort((a, b) => a.id - b.id)[0]);
            setUserAccessGroupResponse({
                assets: [],
                workingOPArea: [],
                workingRegions: [],
                devices: []
            });
        } else if (userConfig?.accessGroups) {
            setUserAccessGroup(userConfig.accessGroups.sort((a, b) => a.id - b.id)[0]);
            setUserSettings(userConfig.accessGroups.sort((a, b) => a.id - b.id)[0]);
            setUserAccessGroupResponse({
                assets: [],
                workingOPArea: [],
                workingRegions: [],
                devices: []
            });
        }
    }, [userConfig?.userSettings, userConfig?.accessGroups]);

    const showToast = (toastMessage: string) => {
        setStateToast({
            message: `${intl.formatMessage({ id: toastMessage })}`,
            open: true,
            type: MessageType.SUCCESS
        });
    };

    const handleYesModal = (newValue: ItemAccessGroup) => {
        setUserSettings(newValue);
        LocalStorageManager.clearAllStoredInfo();
        setStateModal((old) => ({ ...old, openModal: false }));
        showToast('user_session_restarted');
        navigate(config.defaultPath);
    };

    const handleNoModal = () => {
        setStateModal((old) => ({ ...old, openModal: false }));
    };

    const handleChange = (newValue: ItemAccessGroup) => {
        setStateModal({
            openModal: true,
            responseTitle: intl.formatMessage({ id: 'response_title_change_access group' }),
            responseContent: intl.formatMessage({ id: 'response_body_user_restarted' }),
            type: MessageType.INFO,
            onYes: () => handleYesModal(newValue),
            onNo: handleNoModal
        });
    };

    return (
        <Autocomplete
            disableClearable
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(option, value) => {
                if (value.name === '') return true;
                return option.id === value.id;
            }}
            onChange={(event, newValue) => {
                handleChange(newValue);
            }}
            options={userConfig?.accessGroups?.sort((a, b) => a.id - b.id) ?? []}
            renderInput={(params) => (
                <TextField
                    {...params}
                    InputLabelProps={{
                        style: {
                            color: theme.palette.mode === 'dark' ? theme.palette.warning.dark : theme.palette.secondary.dark,
                            fontWeight: 800
                        }
                    }}
                    label={intl.formatMessage({ id: 'access_group' })}
                    placeholder={intl.formatMessage({ id: 'search_to_add' })}
                    sx={{
                        '& label.Mui-focused': {
                            color: theme.palette.mode === 'dark' ? theme.palette.warning.dark : theme.palette.secondary.dark
                        },
                        '& .MuiInput-underline:after': {
                            borderBottomColor: theme.palette.mode === 'dark' ? theme.palette.warning.dark : theme.palette.secondary.dark
                        },
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : theme.palette.secondary.light
                            },
                            '&:hover fieldset': {
                                borderColor: theme.palette.mode === 'dark' ? theme.palette.warning.dark : theme.palette.secondary.dark
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: theme.palette.mode === 'dark' ? theme.palette.warning.dark : theme.palette.secondary.dark
                            },
                            '.MuiInputBase-input': {
                                backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : theme.palette.secondary.light
                            }
                        }
                    }}
                />
            )}
            size="small"
            sx={{
                width: 200,
                '.MuiAutocomplete-inputRoot': {
                    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : theme.palette.secondary.light
                },
                '.MuiSvgIcon-root': {
                    color: theme.palette.mode === 'dark' ? theme.palette.warning.dark : theme.palette.secondary.dark
                }
            }}
            value={userAccessGroup}
        />
    );
};

export default AccessGroupSection;
