import { COLORS } from '_global/Utils/Colors';
import { useConfigurationAtom } from '_global/Utils/hooks/jotai';
import { IconToNode } from '_global/Utils/IconToNode';
import { faClose } from '@fortawesome/pro-solid-svg-icons';
import { Button, Card, Dialog, DialogActions, DialogContent, DialogTitle, Typography, useMediaQuery } from '@mui/material';
import { useAtomValue } from 'jotai';
import { useRemoteConfiguration } from 'logic/configuration/Hooks';
import { QrCode, QrCodeResp } from 'models/qr-code/QRCode';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { qrCodeStyling } from '../qr-code/QRCodeStyling';
import QrCodeView from '../qr-code/QRCodeView';

type ModalProps = {
    isOpen: boolean;
    setIsOpen: (value: boolean) => void;
};

const ModalQrCode = (props: ModalProps) => {
    const { isOpen, setIsOpen } = props;
    const [config] = useRemoteConfiguration();
    const configuration = useAtomValue(useConfigurationAtom);
    const matchesPhone = useMediaQuery('(max-width:600px)');
    const [changeQr, setChangeQr] = useState(false);

    async function getDataQrcode() {
        if (!configuration) return;
        try {
            const response = await fetch(`${configuration.BaseUrl}/authorization/api/QRCode/get-by-name`, {
                method: 'POST',
                body: JSON.stringify({ name: 'Generic' }),
                headers: { 'Content-Type': 'application/json' }
            });

            return response;
        } catch (e) {
            console.error(e);
        }
    }

    const fetchData = async () => {
        const data = await getDataQrcode();

        if (data) {
            const response = (await data.json()) as QrCodeResp;
            delete response.id;

            const correctObj = { ...response } as unknown as QrCode;

            correctObj.initialMapLocation = {
                Longitude: response.initialMapLocation.longitude,
                Latitude: response.initialMapLocation.latitude
            };

            qrCodeStyling.update({
                data: JSON.stringify(correctObj),
                width: matchesPhone ? 300 : 600,
                height: matchesPhone ? 300 : 600
            });
        }
    };

    useEffect(() => {
        if (isOpen && config !== undefined) {
            void fetchData();
        }
    }, [isOpen, config]);

    return (
        <Dialog
            PaperProps={{
                style: {
                    boxShadow: 'none',
                    minWidth: matchesPhone ? '300px' : '650px'
                }
            }}
            fullWidth
            open={isOpen}
            scroll={'body'}
            slotProps={{ backdrop: { style: { background: 'rgba(0,0,0,0.9)' } } }}
        >
            <Card>
                <DialogTitle sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative' }}>
                    <Typography variant={matchesPhone ? 'h4' : 'h2'}> eneida EdgeForce</Typography>
                    <div
                        onClick={() => {
                            setIsOpen(!isOpen);
                        }}
                        style={{ cursor: 'pointer', position: 'absolute', top: 10, right: 25 }}
                    >
                        <IconToNode definition={faClose} size={matchesPhone ? 'sm' : 'lg'} />
                    </div>
                </DialogTitle>
                <DialogContent style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <QrCodeView changeQr={changeQr} qr={qrCodeStyling} />
                </DialogContent>
                <DialogActions sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Button
                        onClick={(event) => {
                            event.preventDefault();
                            setChangeQr(!changeQr);
                        }}
                        size="medium"
                        sx={{ color: 'white', background: COLORS.ENEIDA_ALTERNATIVE_BLUE }}
                        variant={'contained'}
                    >
                        <FormattedMessage id={'alt_qrcode'} />
                    </Button>
                </DialogActions>
            </Card>
        </Dialog>
    );
};

export default ModalQrCode;
